import CacheFactory from "@components/Factories/CacheFactory";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useCartDispatch from "../Cart/useCartDispatch";
import useCheckoutDeliveryDispatch from "../Checkout/Delivery/useCheckoutDeliveryDispatch";
import useCheckoutDetailsDispatch from "../Checkout/Details/useCheckoutDetailsDispatch";
import useCheckoutPaymentDispatch from "../Checkout/Payment/useCheckoutPaymentDispatch";
import useCheckoutQuoteDispatch from "../Checkout/Quote/useCheckoutQuoteDispatch";

const useGlobalInit = () => {
    const [init, setInit] = useState(false);

    const { cart: cartState } = useSelector((state) => state.cartManagementReducer);
    const { details: detailsState } = useSelector((state) => state.checkoutDetailsReducer);
    const { quote: quoteState } = useSelector((state) => state.checkoutQuoteReducer);
    const { delivery: deliveryState } = useSelector((state) => state.checkoutDeliveryReducer);

    const {UPDATE_CART} = useCartDispatch();
    const {SET_DETAILS} = useCheckoutDetailsDispatch();
    const {SET_QUOTE} = useCheckoutQuoteDispatch();
    const {SET_DELIVERY_ALL} = useCheckoutDeliveryDispatch();
    const {SET_PAYMENT} = useCheckoutPaymentDispatch();



    const handleCacheCheck = (key) => {
        if(CacheFactory.has(key)){
            const data = CacheFactory.get(key)
            return data;
        }
        return false
    }

    const handleInitCart = () => {
        const cart = handleCacheCheck('cart')
        cart != false && UPDATE_CART(cart);
    }

    const handleInitDetails = () => {
        // const details = handleCacheCheck('details')
        // details != false && SET_DETAILS(details);
        const quote = handleCacheCheck('quote')

        if(quote != false){
            const detailsData = {
                name: (CacheFactory.has('quote').name) ? CacheFactory.get('quote').name : '',
                telephone: (CacheFactory.has('quote').telephone) ? CacheFactory.get('quote').telephone : '',
                company: (CacheFactory.has('quote').company) ? CacheFactory.get('quote').company : '',
                email: (CacheFactory.has('quote').email) ? CacheFactory.get('quote').email : '',
                deliveryPostcode: (CacheFactory.has('quote').deliveryPostcode) ? CacheFactory.get('quote').deliveryPostcode : '',
                collectionPostcode: (CacheFactory.has('quote')) ? CacheFactory.get('quote').collectionPostcode : '',
                deliveryDate: (CacheFactory.has('deliveryDate')) ? CacheFactory.get('deliveryDate') : detailsState.deliveryDate,
                collectionDate: (CacheFactory.has('collectionDate')) ? CacheFactory.get('collectionDate') : detailsState.collectionDate,
                hireDays: (CacheFactory.has('hireDays')) ? CacheFactory.get('hireDays') : detailsState.hireDays,
              }
              SET_DETAILS(detailsData);
        }

        const delDate = handleCacheCheck('deliveryDate');
        const colDate = handleCacheCheck('collectionDate');
        const hireDays = handleCacheCheck('hireDays');
        const hireWeeks = handleCacheCheck('hireWeeks');

        if(delDate != false) CacheFactory.put('deliveryDate', delDate);
        if(colDate != false) CacheFactory.put('collectionDate', colDate);
        if(hireDays != false) CacheFactory.put('hireDays', hireDays);
        if(hireWeeks != false) CacheFactory.put('hireWeeks', hireWeeks);
        

    }

    const handleInitQuote = () => {
        const quote = handleCacheCheck('quote')
        quote != false && SET_QUOTE(quote);
    }

    const handleInitDelivery = () => {
        const delivery = handleCacheCheck('delivery')
        if(CacheFactory.has('cart') && CacheFactory.has('quote') && delivery){
            cartState != false && quoteState != false & SET_DELIVERY_ALL({transport: delivery});
        }
    }

    const handleInitPayment = () => {
        const payment = handleCacheCheck('payment')
        if(payment && CacheFactory.has('passedQuoteStage') && CacheFactory.has('passedDeliveryStage') && CacheFactory.has('cart') && CacheFactory.has('quote')){
            SET_PAYMENT(payment);
        }
    }



    useEffect(() => {
        if (init) {
            handleInitCart();
            handleInitDetails();
            handleInitQuote();
            handleInitDelivery();
            handleInitPayment();
        }

        return () => {
        };
    }, [init]);

   

    const initGlobal = () => {
        setInit(true);
    };

    return {
        initGlobal,
    };
};

export default useGlobalInit;