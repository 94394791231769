import useGlobalInit from "@components/_hooks/InitHooks/useGlobalInit"
import useRouteHandler from "@components/_hooks/InitHooks/useRouteHandler";
import { useEffect } from "react";

const InitData = () => {
   
	const {initGlobal} = useGlobalInit();
	const {initRouteHandler} = useRouteHandler();

	useEffect(() => {
		initGlobal();
		initRouteHandler();
	}, [])
    
}

export default InitData