import { useDispatch } from "react-redux";
import quoteActions from "@components/Redux/Checkout/Quote/actions";
import detailsActions from "@components/Redux/Checkout/Details/actions";
// import userActions from "@components/Redux/User/actions";
// import modalActions from "@components/Redux/Modals/actions";

const useCheckoutQuoteDispatch = () => {
    const dispatch = useDispatch();

    const SET_QUOTE = (quote) => {
        dispatch({type: quoteActions.CHECKOUT_QUOTE_SET_QUOTE, payload: quote})
    }

    const SET_QUOTE_KEY = (key, value) => {
        dispatch({type: quoteActions.CHECKOUT_QUOTE_SET_KEY, payload: {key: key, value: value}})
    }

    const SET_QUOTE_LOADING_KEY = (key, value) => {
        dispatch({type: quoteActions.CHECKOUT_QUOTE_SET_LOADER, payload: {key: key, value: value}})
    }

    const UPDATE_QUOTE_OBJECT_KEY = (key, value) => {
        dispatch({type: quoteActions.UPDATE_QUOTE_OBJECT_KEY, payload: {key: key, value: value}})
    }

    const RESET_QUOTE = () => {
        dispatch({type: quoteActions.CHECKOUT_QUOTE_RESET})
    }

    return {
        SET_QUOTE,
        SET_QUOTE_KEY,
        SET_QUOTE_LOADING_KEY,
        UPDATE_QUOTE_OBJECT_KEY,
        RESET_QUOTE
    }
}

export default useCheckoutQuoteDispatch;