import { useRouter } from "next/router";
import { useState, useEffect, useRef } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import useAuth from "../useAuth";
import useGeneralActions from "../useGeneralActions";
import { useSelector } from "react-redux";


const useRouteHandler = () => {
    const [init, setInit] = useState(false);

    // const {initCart} = useCartInit();
    const {setPageSpinner} = useGeneralActions();
    const router = useRouter();
    const isFirstRender = useRef(true);
    const {isAgent} = useAuth();
    
    const {pageSpinner} = useSelector((state) => state.globalReducer);

    const show_spinner = () => {
        // const pathName = router.asPath;
        // const found = portalRoutes.find(route => pathName.startsWith(route));
        // return typeof found == 'undefined';
        return !isAgent();
    };
    
    useEffect(() => {
        if (init) {
            isFirstRender.current = false;
        }
    }, [init]);


    useEffect(() => {
        const handleRouteChange = () => {
            NProgress.start();
            if (show_spinner()) {
                setPageSpinner(true)
            }
        };
    
        const handleRouteFinish = () => {
            NProgress.done();
            if (show_spinner() || pageSpinner) {
                setPageSpinner(false)
            }
        };
    
        router.events.on("routeChangeStart", handleRouteChange);
        router.events.on("routeChangeComplete", handleRouteFinish);
        router.events.on("routeChangeError", handleRouteFinish);
    
        return () => {
            router.events.off("routeChangeStart", handleRouteChange);
            router.events.off("routeChangeComplete", handleRouteFinish);
            router.events.off("routeChangeError", handleRouteFinish);
        };
  
    }, [router.asPath, router.events]);

    const initRouteHandler = () => {
        setInit(true);
    };


    return {
        initRouteHandler
    };
};

export default useRouteHandler;