import { useDispatch } from "react-redux";
import deliveryActions from "@components/Redux/Checkout/Delivery/actions";

const useCheckoutDeliveryDispatch = () => {
    const dispatch = useDispatch();

    const SET_DELIVERY_ALL = (data) => {
        dispatch({type: deliveryActions.CHECKOUT_DELIVERY_SET_ALL, payload: data})
    }

    const SET_DELIVERY_KEY = (key, value) => {
        dispatch({type: deliveryActions.CHECKOUT_DELIVERY_SET_KEY, payload: {key: key, value: value}})
    }

    const SET_DELIVERY_LOADING_KEY = (key, value) => {
        dispatch({type: deliveryActions.CHECKOUT_DELIVERY_SET_LOADER, payload: {key: key, value: value}})
    }
    
    const GET_DELIVERY_SURCHARGES = () => {
        dispatch({type: deliveryActions.DELIVERY_GET_SURCHARGES})
    }
    
    const GET_DELIVERY_GET_INSTALL_DERIG = () => {
        dispatch({type: deliveryActions.DELIVERY_GET_INSTALL_DERIG})
    }

    const RESET_DELIVERY = () => {
        dispatch({type: deliveryActions.CHECKOUT_DELIVERY_RESET})
    }

    return {
        SET_DELIVERY_ALL,
        SET_DELIVERY_KEY,
        SET_DELIVERY_LOADING_KEY,
        GET_DELIVERY_SURCHARGES,
        GET_DELIVERY_GET_INSTALL_DERIG,
        RESET_DELIVERY
    }
}

export default useCheckoutDeliveryDispatch;