import { useDispatch } from "react-redux";
import paymentActions from "@components/Redux/Checkout/Payment/actions";
// import userActions from "@components/Redux/User/actions";
// import modalActions from "@components/Redux/Modals/actions";

const useCheckoutPaymentDispatch = () => {
    const dispatch = useDispatch();

    const SET_PAYMENT = (quote) => {
        dispatch({type: paymentActions.CHECKOUT_PAYMENT_SET_PAYMENT, payload: quote})
    }

    const SET_PAYMENT_KEY = (key, value) => {
        dispatch({type: paymentActions.CHECKOUT_PAYMENT_SET_KEY, payload: {key: key, value: value}})
    }
    
    const SET_STEPS = (data) => {
        dispatch({type: paymentActions.CHECKOUT_PAYMENT_SET_STEPS, payload: data})
    }

    const SET_PAYMENT_LOADING_KEY = (key, value) => {
        dispatch({type: paymentActions.CHECKOUT_PAYMENT_SET_LOADER, payload: {key: key, value: value}})
    }

    const UPDATE_PAYMENT_OBJECT_KEY = (key, value) => {
        dispatch({type: paymentActions.UPDATE_PAYMENT_OBJECT_KEY, payload: {key: key, value: value}})
    }
    
    const GET_COUNTRIES = () => {
        dispatch({type: paymentActions.CHECKOUT_PAYMENT_GET_COUNTRIES})
    }

    const SET_CRAFTY_RESULTS = (data) => {
        dispatch({type: paymentActions.PAYMENT_UPDATE_CRAFTY_RESULTS, payload: data})
    }

    const SET_CRAFTY_SELECTED_ADDRESS = (address_id) => {
        dispatch({type: paymentActions.PAYMENT_SET_CRAFTY_SELECTED_ADDRESS, payload: address_id})
    }

    const RESET_PAYMENT = () => {
        dispatch({type: paymentActions.CHECKOUT_PAYMENT_RESET})
    }

    return {
        SET_PAYMENT,
        SET_PAYMENT_KEY,
        SET_STEPS,
        SET_PAYMENT_LOADING_KEY,
        UPDATE_PAYMENT_OBJECT_KEY,
        GET_COUNTRIES,
        SET_CRAFTY_RESULTS,
        SET_CRAFTY_SELECTED_ADDRESS,
        RESET_PAYMENT
    }
}

export default useCheckoutPaymentDispatch;